import React from "react"
import FormCheckbox from "./formCheckbox"

const CheckboxGroup = ({
  label,
  options = [],
  handleChange,
  disableMargin=false,
  value,
  error,
  getChecked,
  getKey,
  className = "",
  labelClassname = "",
  type = "add-property",
}) => {
  return (
    <div className={className || "res-w-half my-2"}>
      {label && (
        <span className={`font-bold px20 black mb-5 ${disableMargin ? "" : "mx-2"} ${labelClassname}`}>
          {label}
        </span>
      )}
      <div className="d-flex flex-wrap align-items-center ">
        {options.map((option, i) => (
          <div
            style={{
              minWidth:
                type === "add-property"
                  ? "22%"
                  : type === "rent-paid"
                  ? "33%"
                  : type === "type"
                  ? "30%"
                  : type === "bed-rooms"
                  ? "35%"
                  : "33%",
            }}
            key={typeof getKey === "function" ? getKey(option) : option.value}
          >
            <FormCheckbox
              label={option.label}
              onChange={(_, checked) => handleChange(option.value, checked)}
              checked={
                typeof getChecked === "function"
                  ? getChecked(option)
                  : value === option.value
              }
            />
          </div>
        ))}
      </div>
      {error && <span className="error mx-2">{error}</span>}
    </div>
  )
}

export default CheckboxGroup
