import React, { useContext, useEffect, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { connect } from "react-redux"
import ListPropertyContext from "../propertyContext"
import FieldDesc from "../fieldDesc"
import FormCheckbox from "../formCheckbox"
import FormInput from "../formInput"
import CheckboxGroup from "../checkboxGroup"

export const isWithFurnitureOptions = [
  { label: <FormattedMessage defaultMessage="Furnitured" />, value: true },
  { label: <FormattedMessage defaultMessage="Not furnitured" />, value: false },
]
// export const propertyTypes = [
//   { label: <FormattedMessage defaultMessage="Villa" />, value: false },
//   { label: <FormattedMessage defaultMessage="Flat" />, value: false },
//   { label: <FormattedMessage defaultMessage="Shop" />, value: false },
//   { label: <FormattedMessage defaultMessage="Land" />, value: false },
//   { label: <FormattedMessage defaultMessage="Farm" />, value: false },
//   { label: <FormattedMessage defaultMessage="Office" />, value: false },
//   { label: <FormattedMessage defaultMessage="Warhouse" />, value: false },
// ]

// export const amenitiesOptions = [
//   { label: <FormattedMessage defaultMessage="Maids room" />, value: false },
//   { label: <FormattedMessage defaultMessage="Study" />, value: false },
//   { label: <FormattedMessage defaultMessage="Garden" />, value: false },
//   { label: <FormattedMessage defaultMessage="A/C & heating" />, value: false },
//   { label: <FormattedMessage defaultMessage="Balcony" />, value: false },
//   { label: <FormattedMessage defaultMessage="Parking" />, value: false },
// ]

export const bedRoomCountOptions = [
  { label: <FormattedMessage defaultMessage="1 bed room" />, value: 1 },
  { label: <FormattedMessage defaultMessage="2 bed rooms" />, value: 2 },
  { label: <FormattedMessage defaultMessage="3 bed rooms" />, value: 3 },
  { label: <FormattedMessage defaultMessage="+4 bed rooms" />, value: 4 },
]

export const isConstructionCompletedOptions = [
  { label: <FormattedMessage defaultMessage="Complete" />, value: true },
  { label: <FormattedMessage defaultMessage="Not Completed" />, value: false },
]

export const bathsCountOptions = [
  { label: <FormattedMessage defaultMessage="1 bath room" />, value: 1 },
  { label: <FormattedMessage defaultMessage="2 bath rooms" />, value: 2 },
  { label: <FormattedMessage defaultMessage="3 bath rooms" />, value: 3 },
  { label: <FormattedMessage defaultMessage="+3 bath rooms" />, value: 4 },
]

const SeventhStep = ({ amenities }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setCurrentStepInputs,
  } = useContext(ListPropertyContext)

  useEffect(() => {
    setCurrentStepInputs([
      "isWithFurniture",
      "amenities",
      "description",
      "isConstructionCompleted",
      "bedRoomsCount",
      "bathsCount",
    ])
  }, [])
  const checkAmenity = () => {}
  const intl = useIntl()

  const amenitiesOptions = useMemo(
    () =>
      amenities.data?.map(a => ({
        label: a.title || a.titleEn,
        value: { ...a },
      })),
    [amenities]
  )

  return (
    <div className="w-100 px-5 h-9/10">
      <FieldDesc>
        <FormattedMessage defaultMessage="Any More details to add" />
      </FieldDesc>
      <div className="w-100 d-flex flex-wrap justify-content-between">
        <CheckboxGroup
          options={isWithFurnitureOptions}
          label={<FormattedMessage defaultMessage="Furniture" />}
          value={values.isWithFurniture}
          error={touched.isWithFurniture && errors.isWithFurniture}
          handleChange={value => setFieldValue("isWithFurniture", value)}
        />

        <CheckboxGroup
          options={bedRoomCountOptions}
          label={<FormattedMessage defaultMessage="Bed Rooms" />}
          value={values.bedRoomsCount}
          error={touched.bedRoomsCount && errors.bedRoomsCount}
          handleChange={value => setFieldValue("bedRoomsCount", value)}
        />

        {amenitiesOptions?.length > 0 && (
          <CheckboxGroup
            options={amenitiesOptions}
            label={<FormattedMessage defaultMessage="Amenities" />}
            value={values.amenities}
            error={touched.amenities && errors.amenities}
            handleChange={(value, checked) =>
              setFieldValue(
                "amenities",
                checked
                  ? [...values.amenities, value]
                  : values.amenities?.filter?.(a => a.id !== value.id)
              )
            }
            getChecked={option =>
              values.amenities?.map(a => a.id).includes?.(option.value?.id)
            }
            getKey={option => option.value?.id}
          />
        )}

        <CheckboxGroup
          options={bathsCountOptions}
          label={<FormattedMessage defaultMessage="Baths" />}
          value={values.bathsCount}
          error={touched.bathsCount && errors.bathsCount}
          handleChange={value => setFieldValue("bathsCount", value)}
        />

        <CheckboxGroup
          options={isConstructionCompletedOptions}
          label={<FormattedMessage defaultMessage="Construction" />}
          value={values.isConstructionCompleted}
          error={
            touched.isConstructionCompleted && errors.isConstructionCompleted
          }
          handleChange={value =>
            setFieldValue("isConstructionCompleted", value)
          }
        />

        <div style={{ height: "210px" }} className="res-w-half">
          <FormInput
            tag="textarea"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            // outerClassName="res-w-half"
            className="w-100"
            placeholder={intl.formatMessage({
              defaultMessage: "Add a description & additional details",
            })}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ data: { amenities } }) => ({ amenities })
export default connect(mapStateToProps)(SeventhStep)
