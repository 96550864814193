import { Checkbox } from "@material-ui/core"
import React from "react"
import { getLabel } from "../../utils"
import { FormLabel } from "./formInput"

const FormCheckbox = ({
  name,
  checked,
  label,
  color = "secondary",
  onChange,
  ...props
}) => {
  return (
    <div className="d-flex align-items-center">
      <Checkbox
        sx={{ color: "red !important" }}
        {...props}
        checked={checked}
        color={color}
        name={name}
        onChange={onChange}
      />
      <FormLabel>{label || getLabel(name)}</FormLabel>
    </div>
  )
}

export default FormCheckbox
