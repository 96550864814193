import React, { useContext, useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import ListPropertyContext from "../propertyContext"
import FieldDesc from "../fieldDesc"
import FormCheckbox from "../formCheckbox"
import FormInput, { FormLabel } from "../formInput"
import FormSelect from "../formSelect"
import { useSelector } from "react-redux"
import ChevDown from "../../../assets/icons/chevDown"

export const rentCheckOptions = [
  { value: "month", label: <FormattedMessage defaultMessage="Monthlly" /> },
  { value: "sixMonth", label: <FormattedMessage defaultMessage="6 month" /> },
  { value: "year", label: <FormattedMessage defaultMessage="Yearly" /> },
]

const FourthStep = () => {
  const intl = useIntl()
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setCurrentStepInputs,
  } = useContext(ListPropertyContext)
  const PopupButton = ({ children, onClick }) => (
    <button
      disabled
      type="button"
      onClick="disabled"
      className="br8 grey px20 d-flex align-items-center justify-content-between flex-1"
      style={{
        border: "1px solid #BFC7D2",
        color: "#2D3436",
        maxWidth: "231px",
        width: "100%",
        height: "48px",
      }}
    >
      {children}
      <ChevDown />
    </button>
  )
  const UnitSelect = ({ children, onClick }) => (
    <button
      type="button"
      onClick={onClick}
      className="br8 grey px20 d-flex align-items-center justify-content-between flex-1"
      style={{
        border: "1px solid #BFC7D2",
        color: "#2D3436",
        maxWidth: "231px",
        width: "100%",
        height: "48px",
      }}
    >
      {children}
      <ChevDown />
    </button>
  )
  const sizeUnitOptions = useSelector(
    state => state?.data?.sizeUnits?.data || []
  )

  const handleUnitChange = unit => {
    setFieldValue("sizeUnit", unit)
  }

  const handleRentCheckChange = rentCheck => {
    setFieldValue("rentCheck", rentCheck)
  }

  useEffect(() => {
    setCurrentStepInputs(["size", "price", "unit", "rentCheck"])
  }, [])

  return (
    <div className="res-w-half h-9/10">
      <FieldDesc>
        <FormattedMessage defaultMessage="Property Size & Price" />
      </FieldDesc>

      <div
        className="d-flex flex-wrap"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "0px",
        }}
      >
        <FormInput
          label={<FormattedMessage defaultMessage="Size" />}
          name="size"
          type="number"
          min="0"
          placeholder={intl.formatMessage({
            defaultMessage: "Property Size",
          })}
          outerClassName="mx-3 my-0"
          style={{
            width: "231px",
            height: "48px",
            marginTop: "0px !important",
          }}
          value={values.size}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.size && errors.size}
        />
        <div className="flex-column" style={{ width: 231 }}>
          {/* <FormLabel>Unit</FormLabel> */}
          {/* <UnitSelect onClick={handleUnitChange} children={sizeUnitOptions}>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "32px",
                marginTop: "0px",
              }}
            >
              Unit
            </div>
          </UnitSelect> */}
          <FormSelect
            label={<FormattedMessage defaultMessage="Unit" />}
            name="sizeUnit"
            addProperty={true}
            placeholder={intl.formatMessage({
              defaultMessage: "Unit",
            })}
            outerClassName="res-w-25"
            style={{ width: "231px", height: "48px" }}
            value={values.sizeUnit}
            onChange={handleUnitChange}
            onBlur={handleBlur}
            options={sizeUnitOptions}
            getOptionLabel={option => option?.title || option?.titleEn}
            getOptionValue={option => option?.id}
            error={touched.sizeUnit && errors.sizeUnit}
          />
          {touched.sizeUnit && errors.size && (
            <span className="error mx-2"></span>
          )}
        </div>
        {/* <FormSelect
          label={<FormattedMessage defaultMessage="Unit" />}
          name="sizeUnit"
          placeholder={intl.formatMessage({
            defaultMessage: "Unit",
          })}
          outerClassName="mx-3 res-w-25"style={{width: '231px', height: '48px' }}
          value={values.sizeUnit}
          onChange={handleUnitChange}
          onBlur={handleBlur}
          options={sizeUnitOptions}
          getOptionLabel={option => option?.title || option?.titleEn}
          getOptionValue={option => option?.id}
          error={touched.sizeUnit && errors.sizeUnit}
        /> */}
      </div>
      <div
        className="d-flex flex-wrap"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormInput
          label={<FormattedMessage defaultMessage="Price" />}
          name="price"
          type="number"
          min="0"
          placeholder={intl.formatMessage({
            defaultMessage: "Property Price",
          })}
          outerClassName="mx-3 res-w-25 my-0"
          style={{
            width: "231px",
            height: "48px",
            marginTop: "0px !important",
          }}
          value={values.price}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.price && errors.price}
        />
        <div className="flex-column" style={{ width: 231 }}>
          <FormLabel>Currency</FormLabel>
          <PopupButton onClick={null} children={null}>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "32px",
                fontSize: '16px'
              }}
            >
              Syrian Pounds SP
            </div>
          </PopupButton>
          {touched.price && errors.price && (
            <span className="error mx-2"></span>
          )}
        </div>
      </div>

      {values.offerType === "rent" && (
        <div className="d-flex justify-content-center rentpaid4thproperty">
          <div className="addPropertyRentPaid">
            <span className="font-bold px20 black mb-5 mx-3">
              <FormattedMessage defaultMessage="Rent Paid" />
            </span>

            <div className="w-100 d-flex align-items-center justify-content-between">
              {rentCheckOptions.map(({ label, value }) => (
                <FormCheckbox
                  key={value}
                  label={label}
                  onChange={() => handleRentCheckChange(value)}
                  checked={values.rentCheck === value}
                />
              ))}
            </div>
            {touched.rentCheck && errors.rentCheck && (
              <span className="error mx-2">{errors.rentCheck}</span>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default FourthStep
